
/** List of Client */
export const GET_REVENUE_LIST_REQUEST = 'GET_REVENUE_LIST_REQUEST';
export const SET_REVENUE_LIST_REQUEST = 'SET_REVENUE_LIST_REQUEST';

//Create REVENUE
export const ADD_REVENUE = 'ADD_REVENUE';
export const ADD_REVENUE_REQUEST = 'ADD_REVENUE_REQUEST';

//error
export const GET_ERROR = 'GET_ERROR';