
/** List of Client */
export const GET_CALL_LIST_REQUEST = 'GET_CALL_LIST_REQUEST';
export const SET_CALL_LIST_REQUEST = 'SET_CALL_LIST_REQUEST';

//Create CALL
export const ADD_CALL = 'ADD_CALL';
export const ADD_CALL_REQUEST = 'ADD_CALL_REQUEST';

//error
export const GET_ERROR = 'GET_ERROR';