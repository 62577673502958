
/** List of MONTH Subbmision */
export const GET_MONTH_SUBMISSION_REQUEST = 'GET_MONTH_SUBMISSION_REQUEST';
export const SET_MONTH_SUBMISSION_REQUEST = 'SET_MONTH_SUBMISSION_REQUEST';

/** List of MONTH Interview */
export const GET_MONTH_INTERVIEW_REQUEST = 'GET_MONTH_INTERVIEW_REQUEST';
export const SET_MONTH_INTERVIEW_REQUEST = 'SET_MONTH_INTERVIEW_REQUEST';

/** List of MONTH Offer */
export const GET_MONTH_OFFER_REQUEST = 'GET_MONTH_OFFER_REQUEST';
export const SET_MONTH_OFFER_REQUEST = 'SET_MONTH_OFFER_REQUEST';

/** List of MONTH Hire */
export const GET_MONTH_HIRE_REQUEST = 'GET_MONTH_HIRE_REQUEST';
export const SET_MONTH_HIRE_REQUEST = 'SET_MONTH_HIRE_REQUEST';











