
/** List of Daily Target */
export const GET_DT_LIST_REQUEST = 'GET_DT_LIST_REQUEST';
export const SET_DT_LIST_REQUEST = 'SET_DT_LIST_REQUEST';

/** List of Todays Interview */
export const GET_TOIN_LIST_REQUEST = 'GET_TOIN_LIST_REQUEST';
export const SET_TOIN_LIST_REQUEST = 'SET_TOIN_LIST_REQUEST';

/** List of Month Offer */
export const GET_MONOF_LIST_REQUEST = 'GET_MONOF_LIST_REQUEST';
export const SET_MONOF_LIST_REQUEST = 'SET_MONOF_LIST_REQUEST';

/** List of Month to be join */
export const GET_MONTHJOIN_LIST_REQUEST = 'GET_MONTHJOIN_LIST_REQUEST';
export const SET_MONTHJOIN_LIST_REQUEST = 'SET_MONTHJOIN_LIST_REQUEST';

/** List of Month to be join */
export const GET_ATSACTI_LIST_REQUEST = 'GET_ATSACTI_LIST_REQUEST';
export const SET_ATSACTI_LIST_REQUEST = 'SET_ATSACTI_LIST_REQUEST';

/** List of Graph API data */
export const GET_ATSGRAPH_LIST_REQUEST = 'GET_ATSGRAPH_LIST_REQUEST';
export const SET_ATSGRAPH_LIST_REQUEST = 'SET_ATSGRAPH_LIST_REQUEST';