import React, { Component } from 'react';
import Routes from './routes/Routes';

// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes

// default
import './assets/scss/theme.scss';
import { Cookies } from 'react-cookie';

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';


// configure fake backend
configureFakeBackend();

/**
 * Main app component
 */
class App extends Component {
  render() {
  //   let cookies = new Cookies();
  //   window.onbeforeunload = function() {
  //     cookies.remove('user', { path: '/' })
  //  }
  
   
    return <Routes></Routes>;
  }
}

export default App;
