
/** List of Today Subbmision */
export const GET_YESTERDAY_SUBMISSION_REQUEST = 'GET_YESTERDAY_SUBMISSION_REQUEST';
export const SET_YESTERDAY_SUBMISSION_REQUEST = 'SET_YESTERDAY_SUBMISSION_REQUEST';

/** List of YESTERDAY Interview */
export const GET_YESTERDAY_INTERVIEW_REQUEST = 'GET_YESTERDAY_INTERVIEW_REQUEST';
export const SET_YESTERDAY_INTERVIEW_REQUEST = 'SET_YESTERDAY_INTERVIEW_REQUEST';

/** List of YESTERDAY Offer */
export const GET_YESTERDAY_OFFER_REQUEST = 'GET_YESTERDAY_OFFER_REQUEST';
export const SET_YESTERDAY_OFFER_REQUEST = 'SET_YESTERDAY_OFFER_REQUEST';

/** List of YESTERDAY Hire */
export const GET_YESTERDAY_HIRE_REQUEST = 'GET_YESTERDAY_HIRE_REQUEST';
export const SET_YESTERDAY_HIRE_REQUEST = 'SET_YESTERDAY_HIRE_REQUEST';











