
/** List of Client */
export const GET_MONTH_LIST_REQUEST = 'GET_MONTH_LIST_REQUEST';
export const SET_MONTH_LIST_REQUEST = 'SET_MONTH_LIST_REQUEST';

//Create MONTH
export const ADD_MONTH = 'ADD_MONTH';
export const ADD_MONTH_REQUEST = 'ADD_MONTH_REQUEST';

//error
export const GET_ERROR = 'GET_ERROR';