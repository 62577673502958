
/** List of DATE Subbmision */
export const GET_PENDING_APPROVAL_REQUEST = 'GET_PENDING_APPROVAL_REQUEST';
export const SET_PENDING_APPROVAL_REQUEST = 'SET_PENDING_APPROVAL_REQUEST';


/** List of DATE Subbmision */
export const GET_PENDING_OFFER_REQUEST = 'GET_PENDING_OFFER_REQUEST';
export const SET_PENDING_OFFER_REQUEST = 'SET_PENDING_OFFER_REQUEST';




/** List of DATE Subbmision */
export const GET_ACTIVE_REQUIREMENT_REQUEST = 'GET_ACTIVE_REQUIREMENT_REQUEST';
export const SET_ACTIVE_REQUIREMENT_REQUEST = 'SET_ACTIVE_REQUIREMENT_REQUEST';

/** List of DATE Subbmision */
export const GET_DEACTIVE_REQUIREMENT_REQUEST = 'GET_DEACTIVE_REQUIREMENT_REQUEST';
export const SET_DEACTIVE_REQUIREMENT_REQUEST = 'SET_DEACTIVE_REQUIREMENT_REQUEST';



