/** List of Requirement */
export const GET_REQUIREMENTS_LIST_REQUEST = 'GET_REQUIREMENTS_LIST_REQUEST';
export const SET_REQUIREMENTS_LIST_REQUEST = 'SET_REQUIREMENTS_LIST_REQUEST';
/** List of Requirement */
export const GET_CRITICAL_REQUIREMENTS_LIST = 'GET_CRITICAL_REQUIREMENTS_LIST';
export const SET_CRITICAL_REQUIREMENTS_LIST = 'SET_CRITICAL_REQUIREMENTS_LIST';

/** List of Requirement */
export const SAVE_REQUIREMENTS = 'SAVE_REQUIREMENTS';
export const SET_SAVE_REQUIREMENTS = 'SET_SAVE_REQUIREMENTS';

//modal
export const GET_REQUIREMENTS_MODAL = 'GET_REQUIREMENTS_MODAL';
export const HIDE_REQUIREMENTS_MODAL = 'HIDE_REQUIREMENTS_MODAL';
//
export const UPDATE_REQUIREMNT ="UPDATE_REQUIREMNT"