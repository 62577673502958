
/** List of Client */
export const GET_CLIENT_LIST_REQUEST = 'GET_CLIENT_LIST_REQUEST';
export const SET_CLIENT_LIST_REQUEST = 'SET_CLIENT_LIST_REQUEST';
//Add Client
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'


export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CLIENT_MODAL = 'GET_CLIENT_MODAL';
export const HIDE_CLIENT_MODAL = 'HIDE_CLIENT_MODAL';
