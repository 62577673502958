
/** List of LIST */
export const GET_LIST_LIST_REQUEST = 'GET_LIST_LIST_REQUEST';
export const SET_LIST_LIST_REQUEST = 'SET_LIST_LIST_REQUEST';


/** Offer of LIST */
export const GET_OFFER_LIST_REQUEST = 'GET_OFFER_LIST_REQUEST';
export const SET_OFFER_LIST_REQUEST = 'SET_OFFER_LIST_REQUEST';

/** ViewToBeJoin of LIST */
export const GET_VTBJ_LIST_REQUEST = 'GET_VTBJ_LIST_REQUEST';
export const SET_VTBJ_LIST_REQUEST = 'SET_VTBJ_LIST_REQUEST';

/** Join  LIST */
export const GET_JOIN_LIST_REQUEST = 'GET_JOIN_LIST_REQUEST';
export const SET_JOIN_LIST_REQUEST = 'SET_JOIN_LIST_REQUEST';

/** BD  LIST */
export const GET_BD_LIST_REQUEST = 'GET_BD_LIST_REQUEST';
export const SET_BD_LIST_REQUEST = 'SET_BD_LIST_REQUEST';