module.exports = {

    baseUrl: 'https://atsapi.archesoftronix.in/api/',
  //  baseUrl: 'https://qaatsinhousebe.archesoftronix.in/api/',

    //Api for attendance management
    punchFileUpload: 'https://punchsystem.archesoftronix.in/auth/',
};




