import axios from 'axios';
import { baseUrl } from './baseurl';
//import { setSession } from './authUtils';

const api = axios.create({
    baseURL: baseUrl,
});

api.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config['headers']['Authorization'] = 'Bearer ' + localStorage.getItem('token');
       
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        const originalRequest = error.config;
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401 && !originalRequest._retry) {
            //console.log('Localstorage vals', localStorage.getItem('token'));
            return axios
                .post(baseUrl + '/Token/GetRefreshToken', {
                    Token: localStorage.getItem('token'),
                    RefreshToken: localStorage.getItem('refresh_token'),
                })
                .then((res) => {
                    if (res.status === 200 && res.data.Data?.Token && res.data.Data?.RefreshToken) {
                        localStorage.setItem('token', res.data.Data?.Token);
                        localStorage.setItem('refresh_token', res.data.Data?.RefreshToken);
                        originalRequest.headers.Authorization = `Bearer ` + res.data.Data?.Token;
                        return axios(originalRequest);
                    }
                });
        }

        // return axios(originalRequest);

        return Promise.reject(error);
    }
);

// api.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         //console.log('>>>>>>>>>>>>>>???????????????? After ................', error.response, error.config);
//         const originalRequest = error.config;
//         // let guid = localStorage.getItem('crolGUID');
//         // api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

// if (error.response.status === 401 && !originalRequest._retry) {
//     // originalRequest._retry = true;
//     //console.log('Localstorage vals', localStorage.getItem('token'));
//     return axios
//         .post(baseUrl + '/Token/GetRefreshToken', {
//             Token: localStorage.getItem('token'),
//             RefreshToken: localStorage.getItem('refresh_token'),
//         })
//         .then((res) => {
//             if (res.status === 200) {
//                 // setSession( res.data.token, '' );
//                 //console.log('?????????????????????? Response from refresh token api /////..........', res);
//                 localStorage.setItem('token', res.data.Data?.Token);
//                 localStorage.setItem('refresh_token', res.data.Data?.RefreshToken);
//                 originalRequest.headers.Authorization = `Bearer ` + res.data.Data?.Token;
//                 return axios(originalRequest);
//             }
//         });
// }

//         return Promise.reject(error);
//     }
// );

export default api;