
/** List of Client */
export const SET_TODAY_ANALYSIS = 'SET_TODAY_ANALYSIS';
export const SET_TODAY_GRAPH_ANALYSIS = 'SET_TODAY_GRAPH_ANALYSIS';
export const SET_TODAY_TARGET_GRAPH_ANALYSIS = 'SET_TODAY_TARGET_GRAPH_ANALYSIS';
export const SET_TODAY_TARGET_GRAPH_ANALYSIS_ADMIN = 'SET_TODAY_TARGET_GRAPH_ANALYSIS_ADMIN';


export const SET_YESTERDAY_ANALYSIS = 'SET_YESTERDAY_ANALYSIS';
export const SET_YESTERDAY_GRAPH_ANALYSIS = 'SET_YESTERDAY_GRAPH_ANALYSIS';
export const SET_YESTERDAY_TARGET_GRAPH_ANALYSIS = 'SET_YESTERDAY_TARGET_GRAPH_ANALYSIS';
export const SET_YESTERDAY_TARGET_GRAPH_ANALYSIS_ADMIN = 'SET_YESTERDAY_TARGET_GRAPH_ANALYSIS_ADMIN';


export const SET_WEEK_ANALYSIS = 'SET_WEEK_ANALYSIS';
export const SET_WEEK_GRAPH_ANALYSIS = 'SET_WEEK_GRAPH_ANALYSIS';
export const SET_WEEK_TARGET_GRAPH_ANALYSIS = 'SET_WEEK_TARGET_GRAPH_ANALYSIS';
export const SET_WEEK_TARGET_GRAPH_ANALYSIS_ADMIN = 'SET_WEEK_TARGET_GRAPH_ANALYSIS_ADMIN';


export const SET_MONTH_ANALYSIS = 'SET_MONTH_ANALYSIS';
export const SET_MONTH_GRAPH_ANALYSIS = 'SET_MONTH_GRAPH_ANALYSIS';
export const SET_MONTH_TARGET_GRAPH_ANALYSIS = 'SET_MONTH_TARGET_GRAPH_ANALYSIS';
export const SET_MONTH_TARGET_GRAPH_ANALYSIS_ADMIN = 'SET_MONTH_TARGET_GRAPH_ANALYSIS_ADMIN';



export const SET_TILLDATE_ANALYSIS = 'SET_TILLDATE_ANALYSIS';
export const SET_TILLDATE_GRAPH_ANALYSIS = 'SET_TILLDATE_GRAPH_ANALYSIS';

export const SET_GENERAL_ANALYSIS = 'SET_GENERAL_ANALYSIS';




