


/** List of datewise Report */
export const GET_DATEWISE_LIST_REQUEST = 'GET_DATEWISE_LIST_REQUEST';
export const SET_DATEWISE_LIST_REQUEST = 'SET_DATEWISE_LIST_REQUEST';

/** List of MonthCount Report */
export const GET_MONTHCOUNT_LIST_REQUEST = 'GET_MONTHCOUNT_LIST_REQUEST';
export const SET_MONTHCOUNT_LIST_REQUEST = 'SET_MONTHCOUNT_LIST_REQUEST';

/** List of Today Report */
export const GET_TODAY_LIST_REQUEST = 'GET_TODAY_LIST_REQUEST';
export const SET_TODAY_LIST_REQUEST = 'SET_TODAY_LIST_REQUEST';

/** List of Revenue Report */
export const GET_REVENU_LIST_REQUEST = 'GET_REVENU_LIST_REQUEST';
export const SET_REVENU_LIST_REQUEST = 'SET_REVENU_LIST_REQUEST';

/** List of Requirement Progress Report */
export const GET_RPR_LIST_REQUEST = 'GET_RPR_LIST_REQUEST';
export const SET_RPR_LIST_REQUEST = 'SET_RPR_LIST_REQUEST';