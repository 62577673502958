
/** List of WEEK Subbmision */
export const GET_WEEK_SUBMISSION_REQUEST = 'GET_WEEK_SUBMISSION_REQUEST';
export const SET_WEEK_SUBMISSION_REQUEST = 'SET_WEEK_SUBMISSION_REQUEST';

/** List of WEEK Interview */
export const GET_WEEK_INTERVIEW_REQUEST = 'GET_WEEK_INTERVIEW_REQUEST';
export const SET_WEEK_INTERVIEW_REQUEST = 'SET_WEEK_INTERVIEW_REQUEST';

/** List of WEEK Offer */
export const GET_WEEK_OFFER_REQUEST = 'GET_WEEK_OFFER_REQUEST';
export const SET_WEEK_OFFER_REQUEST = 'SET_WEEK_OFFER_REQUEST';

/** List of WEEK Hire */
export const GET_WEEK_HIRE_REQUEST = 'GET_WEEK_HIRE_REQUEST';
export const SET_WEEK_HIRE_REQUEST = 'SET_WEEK_HIRE_REQUEST';











