
/** List of Today Subbmision */
export const GET_TODAY_SUBMISSION_REQUEST = 'GET_TODAY_SUBMISSION_REQUEST';
export const SET_TODAY_SUBMISSION_REQUEST = 'SET_TODAY_SUBMISSION_REQUEST';

/** List of Today Interview */
export const GET_TODAY_INTERVIEW_REQUEST = 'GET_TODAY_INTERVIEW_REQUEST';
export const SET_TODAY_INTERVIEW_REQUEST = 'SET_TODAY_INTERVIEW_REQUEST';

/** List of Today Offer */
export const GET_TODAY_OFFER_REQUEST = 'GET_TODAY_OFFER_REQUEST';
export const SET_TODAY_OFFER_REQUEST = 'SET_TODAY_OFFER_REQUEST';

/** List of Today Hire */
export const GET_TODAY_HIRE_REQUEST = 'GET_TODAY_HIRE_REQUEST';
export const SET_TODAY_HIRE_REQUEST = 'SET_TODAY_HIRE_REQUEST';











