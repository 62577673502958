
/** List of DATE Subbmision */
export const GET_DATE_SUBMISSION_REQUEST = 'GET_DATE_SUBMISSION_REQUEST';
export const SET_DATE_SUBMISSION_REQUEST = 'SET_DATE_SUBMISSION_REQUEST';

/** List of DATE Interview */
export const GET_DATE_INTERVIEW_REQUEST = 'GET_DATE_INTERVIEW_REQUEST';
export const SET_DATE_INTERVIEW_REQUEST = 'SET_DATE_INTERVIEW_REQUEST';

/** List of DATE Offer */
export const GET_DATE_OFFER_REQUEST = 'GET_DATE_OFFER_REQUEST';
export const SET_DATE_OFFER_REQUEST = 'SET_DATE_OFFER_REQUEST';

/** List of DATE Hire */
export const GET_DATE_HIRE_REQUEST = 'GET_DATE_HIRE_REQUEST';
export const SET_DATE_HIRE_REQUEST = 'SET_DATE_HIRE_REQUEST';











